<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="modal-user-add"
      ref="user"
      title="Dodaj Usera"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @show="resetForm"
      @cancel="resetForm"
      @ok="handleModalOk"
    >
      <template #modal-header="{ title, close }">
        <!-- Emulate built in modal header close button action -->
        <h5>Dodaj Usera</h5>
        <b-button size="sm" variant="outline-primary" @click="close()">
          Zamknij
        </b-button>
        <b-button size="sm" variant="outline-warning" @click="mock()">
          Nanieś
        </b-button>
      </template>

      <b-form novalidate>
        <b-form-group
          label="username"
          label-for="username"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.username"></b-form-input>
        </b-form-group>

        <b-form-group
          label="password"
          label-for="password"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.password"></b-form-input>
        </b-form-group>

        <b-form-group
          label="email"
          label-for="email"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.email"></b-form-input>
        </b-form-group>

        <div
          class="form-group"
        >
          <Select
            id="insurer_uuid"
            v-model="form.broker_uuid"
            :options="brokersForSelect"
            label="broker_uuid"
            name="broker_uuid"
          />
        </div>

        <b-form-group
          label="pesel"
          label-for="pesel"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input
            id="input"
            v-model="form.pesel"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="first_name"
          label-for="first_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input
            id="input"
            v-model="form.first_name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="surname"
          label-for="surname"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.surname"></b-form-input>
        </b-form-group>

        <b-form-group
          label="middle_name"
          label-for="middle_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.middle_name"></b-form-input>
        </b-form-group>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { USER_ADD } from "@/core/services/store/user.module";
import { baseURL } from "@/core/services/api.service.js";
import _ from "lodash";
import {mapGetters} from "vuex";
import Select from "@/view/content/components/select/Select";

export default {
  name: "ModalUserAdd",
  components: { Select },
  data() {
    return {
      formState: null,
      form: {
        username: "",
        // account_status_uuid: "",
        // roles: [],
        password: "",
        email: "",
        broker_uuid: "",
        pesel: "",
        first_name: "",
        surname: "",
        middle_name: ""
      }
    };
  },
  computed: {
    ...mapGetters(["brokers"]),
    getBaseURL() {
      return baseURL;
    },
    brokersForSelect() {
      const brokersForSelect = [];
      for (const brokers of this.brokers) {
        let v = {
          text: brokers.name,
          value: brokers.uuid
        };
        brokersForSelect.push(v);
      }
      return brokersForSelect;
    }
  },
  mounted() {},
  methods: {
    mock() {
      this.form.username = "user" + _.random(1000, 9999);
      // this.form.account_status_uuid = "";
      // this.form.roles = [];
      this.form.password = "dev";
      this.form.email = this.form.username + "@email.com";
      this.form.broker_uuid = "7Q058T032W8FJ8W66A04N1VNHB";
      this.form.pesel = "87010132871";
      this.form.first_name = "Jan";
      this.form.surname = "Polak";
      this.form.middle_name = "Henryk";
    },
    resetForm() {
      // this.form.username = "";
      // this.form.account_status_uuid = "";
      // this.form.roles = [];
      // this.form.password = "";
      // this.form.email = "";
      // this.form.broker_uuid = "";
      // this.form.pesel = "";
      // this.form.first_name = "";
      // this.form.surname = "";
      // this.form.middle_name = "";
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    validateForm() {
      this.formState = true;
    },
    handleSubmit() {
      this.validateForm();
      if (this.formState) {
        let formToSend = this.form;
        this.$store.dispatch(USER_ADD, formToSend);
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("modal-user-add");
        });
      }
    }
  }
};
</script>
