<template>
  <div>
    <h3 class="text-secondary content-heading">Ustawienia > User</h3>
    <div class="row">
      <div class="col-12">
        <div class="wide-box">
          <div class="card border-radius-1">
            <div class="card-header d-flex align-items-end">
              <div class="card-title flex-grow-1">
                <div class="card-toolbar">
                  <a v-b-modal.modal-user-add class="">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Code/Plus.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <ModalUserAdd />
                </div>
              </div>
            </div>
            <div class="card-body">
              <b-table
                :items="usersList.items"
                :fields="usersList.fields"
                responsive="sm"
                show-empty
                small
              >
                <template #cell(actions)="row">
                  <b-button size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? "Hide" : "Show" }} Details
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1"
                    @click="info(row.item, row.index, $event.target)"
                  >
                    Info modal
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1"
                    @click="edit(row.item, row.index, $event.target)"
                  >
                    Edit
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>

              <!-- Info modal -->
              <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                ok-only
                @hide="resetInfoModal"
              >
                <pre>{{ infoModal.content }}</pre>
              </b-modal>

              <ModalUserEdit :id="editModal.id" :uuid="editModal.uuid" />

              <!--              <ClassicTable-->
              <!--                :items="insurersList"-->
              <!--                :no-headers="false"-->
              <!--                title-style="mb-0"-->
              <!--              />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalUserAdd from "@/view/content/components/modal/ModalUserAdd";
import ModalUserEdit from "@/view/content/components/modal/ModalUserEdit";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "SettingsInsurerView",
  components: { ModalUserAdd, ModalUserEdit },
  data() {
    return {
      selected: [],
      usersList: {
        fields: [
          // {
          //   key: "uuid",
          //   label: "UUID"
          // },
          {
            key: "username",
            label: "username"
          },
          {
            key: "email",
            label: "E-mail"
          },
          {
            key: "first_name",
            label: "first_name"
          },
          {
            key: "middle_name",
            label: "middle_name"
          },
          {
            key: "surname",
            label: "surname"
          },
          {
            key: "pesel",
            label: "pesel"
          },
          {
            key: "status",
            label: "status"
          },
          // {
          //   key: "status_uuid",
          //   label: "status_uuid"
          // },
          {
            key: "actions",
            label: "Akcje"
          }
        ],
        items: []
      },
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      editModal: {
        id: "edit-modal",
        uuid: ""
      }
    };
  },
  computed: {
    ...mapGetters(["users"])
  },
  watch: {
    users(newVal) {
      this.parseDataForTable(newVal);
    }
  },
  mounted() {
    /**
     * TODO This need to be reworked as when refreshing, data is not available yet so he table is empty
     */
    this.parseDataForTable(this.users);
  },
  methods: {
    edit(item, index, button) {
      this.editModal.uuid = item.uuid;
      this.$root.$emit("bv::show::modal", this.editModal.id, button);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetEditModal() {
      this.infoModal.uuid = "";
    },
    parseDataForTable(users) {
      this.usersList.items = [];
      _.forEach(users, user => {
        this.usersList.items.push({
          uuid: user.uuid,
          email: user.email,
          first_name: user.first_name,
          middle_name: user.middle_name,
          pesel: user.pesel,
          status: user.status,
          status_uuid: user.status_uuid,
          surname: user.surname,
          username: user.username
        });
      });
    }
  }
};
</script>
