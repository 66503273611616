<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="id"
      ref="modal"
      title="Edytuj Usera"
      ok-title="Zaktualizuj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @cancel="resetForm"
      @ok="handleModalOk"
      @shown="fillModal"
    >
      <b-form novalidate>
        <b-form-group
          label="username"
          label-for="username"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.username"></b-form-input>
        </b-form-group>

        <b-form-group
          label="password"
          label-for="password"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.password"></b-form-input>
        </b-form-group>

        <b-form-group
          label="email"
          label-for="email"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.email"></b-form-input>
        </b-form-group>

        <div
          class="form-group"
        >
          <Select
            id="insurer_uuid"
            v-model="form.broker_uuid"
            :options="brokersForSelect"
            label="broker_uuid"
            name="broker_uuid"
          />
        </div>

        <b-form-group
          label="pesel"
          label-for="pesel"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input
            id="input"
            v-model="form.pesel"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="first_name"
          label-for="first_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input
            id="input"
            v-model="form.first_name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="surname"
          label-for="surname"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.surname"></b-form-input>
        </b-form-group>

        <b-form-group
          label="middle_name"
          label-for="middle_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.middle_name"></b-form-input>
        </b-form-group>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { USER_EDIT } from "@/core/services/store/user.module";
import { baseURL } from "@/core/services/api.service.js";
import {mapGetters} from "vuex";
import _ from "lodash";
import Select from "@/view/content/components/select/Select";

export default {
  name: "ModalUserEdit",
  components: { Select },
  props: ["id", "uuid"],
  data() {
    return {
      user: null,
      formState: null,
      form: {
        username: "",
        // account_status_uuid: "",
        // roles: [],
        password: "",
        email: "",
        broker_uuid: "",
        pesel: "",
        first_name: "",
        surname: "",
        middle_name: ""
      }
    };
  },
  computed: {
    ...mapGetters(["users", "brokers"]),
    getBaseURL() {
      return baseURL;
    },
    brokersForSelect() {
      const brokersForSelect = [];
      for (const brokers of this.brokers) {
        let v = {
          text: brokers.name,
          value: brokers.uuid
        };
        brokersForSelect.push(v);
      }
      return brokersForSelect;
    }
  },
  mounted() {},
  methods: {
    resetForm() {
      // this.form.username = "";
      // this.form.account_status_uuid = "";
      // this.form.roles = [];
      // this.form.password = "";
      // this.form.email = "";
      // this.form.broker_uuid = "";
      // this.form.pesel = "";
      // this.form.first_name = "";
      // this.form.surname = "";
      // this.form.middle_name = "";
    },
    validateForm() {
      this.formState = true;
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.validateForm();
      if (this.formState) {
        let formToSend = this.form;
        this.$store.dispatch(USER_EDIT, {
          uuid: this.uuid,
          ...formToSend
        });
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide(this.id);
        });
      }
    },
    fillModal() {
      const user = _.find(this.users, o => {
        return o.uuid === this.uuid;
      });
      if (user !== undefined) {
        this.user = user;
        this.form.username = this.user.username;
        // this.form.account_status_uuid = this.user.account_status_uuid;
        // this.form.roles = this.user.roles;
        this.form.password = this.user.password;
        this.form.email = this.user.email;
        this.form.broker_uuid = this.user.broker_uuid;
        this.form.pesel = this.user.pesel;
        this.form.first_name = this.user.first_name;
        this.form.surname = this.user.surname;
        this.form.middle_name = this.user.middle_name;
      }
    }
  }
};
</script>
