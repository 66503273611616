<template>
  <div class="container-fluid px-0 h-100">
    <SettingsUserView />
  </div>
</template>

<script>
import SettingsUserView from "../../../content/settings/user/View";
import { USERS_LIST, BROKERS_LIST } from "@/core/services/store/user.module";

export default {
  name: "SettingsUserPageView",
  components: { SettingsUserView },
  mounted() {
    this.$store.dispatch(USERS_LIST);
    this.$store.dispatch(BROKERS_LIST);
  }
};
</script>
